// import React from 'react';

import 'util/initI18nDates';
import { initDiag } from 'util/diagnostics';

import '../../styles/globals.scss';
import MyApp from 'root/App';
import { appWithTranslation } from 'next-i18next';
import nextI18NextConfig from '../../next-i18next.config';

initDiag();

console.log(nextI18NextConfig);

export default appWithTranslation(MyApp, nextI18NextConfig);
