import { IRegionShort } from 'api/region/types';
import { WeatherType } from 'types';
import { ReturnBySki } from 'util/decodeReturnBySki';
import { INews, ResortTopRated } from 'api/home/types';
import { SortingType } from 'components/common/Grid/types';

export interface IFirstHandReport {
  uuid: string,
  resortUuid?: string,
  title?: string,
  body?: string,
  image?: string,
  largeImage?: string,
  name?: string,
  date: string,
  viewCount?: number,
}

export type IResortReports = {
  pagination: IPaginationResponse;
  reportsList: Array<IFirstHandReport>;
};

export type IPaginationResponse = {
  count: number;
  limit: number;
  page: number;
  orderBy?: string;
  direction?: SortingType
};

export type IResortReviews = {
  pagination: IPaginationResponse;
  reviewsList: Array<IRatingItem>;
};

export type IRatingItem = IReview & Partial<IStarRating>;

export type IReview = {
  uuid?: string;
  date: string;
  firstName: string;
  lastName: string;
  review: string;
  email: string;
  pros?: string;
  cons?: string;
};

export type IStarRating = {
  overall: number;
  beginner: number;
  intermediate: number;
  expert: number;
  allTerrain: number;
  familyFriendly: number;
  apresSki: number;
  terrainPark: number;
  overallOther: number;
};

export type Partial<T> = {
  [P in keyof T]?: T[P];
};

export type IReviewsGeneral = {
  generalRating: IStarRating;
  totalReviews: ITotalReviews;
};

export type IRelatedNews = Array<INews>;

export type ITotalReviews = {
  1: number;
  2: number;
  3: number;
  4: number;
  5: number;
};

export type IRatingForm = IReviewForm & Omit<IReview, 'date'> & { date: Date } & Partial<IStarRating>;

export type IReviewForm = {
  resortUuid: string;
  powderAlarm?: boolean;
  forecast?: boolean;
  snowReport?: boolean;
  news?: boolean;
};

/**
 * Short resort info to cache for building pathes and create id <-> slug mappings
 */
export interface IResortPathInfo {
  /**
     * Resort short untranslated title
     */
  title_short: string;
  /**
     * Resort untranslated title
     */
  title_original: string;
  /**
     * Resort title
     */
  title: string;
  /**
     * Region prefix code
     */
  region: string;
  /**
     * Region English Name
     */
  region_en: string;
  /**
     * Country code
     */
  country_code: string;
  /**
     * Resort slug
     */
  slug: string;
  /**
     * Resort uuid
     */
  uuid: string;
  /**
     * Resort external domain
     * If specified, means resort is not hosted on current domain and should have external link
     */
  domain?: string;
  /**
     * If resort has separate ski rental page
     */
  hasSkiRental: boolean;
  /**
     * Region id
     */
  region_id: string;
  /**
   * If resort has an opportunity to book flights
   */
  hasFlights: boolean
  /**
   * If resort has an opportunity to rent a car
   */
  hasCarRental: boolean
}

export interface IResortNearby extends IResortPathInfo {
  distance: number;

  /** Class represents the tier of ski resort, from "A" to "D" */
  class: string;
}

export enum SurfaceType {
  Powder = 1,
  PackedPowder = 2,
  MachineMade = 3,
  MachineGroomed = 4,
  HardPacked = 5,
  SpringSnow = 6,
  CornSnow = 7,
  LooseGranular = 8,
  FrozenGranular = 9,
  Granular = 10,
  IcySpots = 11,
  BareSpots = 12,
  Obstacles = 13,
  SkiersPacked = 14,
  VariableConditions = 15,
  WetSnow = 16,
  ThinCover = 17,
  WindBlown = 18,
}

export enum OpenFlag {
  Open = 1,
  Closed = 2,
  TemporarilyClosed = 3,
  WeekendsOnly = 4,
  NoReportAvailable = 5,
  PermanentlyClosed = 6,
}

export enum ServiseOpenFlag {
  OpenResorts = 'open-resorts',
  top72 = 'top-72',
}

export enum ResortStatus {
  partner = 'partner',
  openResorts = 'open-resorts',
  projectedClosing = 'projected-closing',
  openingDates = 'opening-dates',
}

export enum ResortWeatherLocation {
  base = 'base',
  summit = 'summit',
}

export enum ResortWeatherType {
  daily = 'daily',
  hourly = 'hourly',
}

export enum ResortKayakServicesTab {
  flights = 'airports_profile',
}

export enum ResortOverviewTab {
  overview = 'resort_profile',
  insideScoop = 'inside_scoop',
  restaurant = 'restaurant_profile',
  terrain = 'mountain_overview',
  transportation = 'transportation_profile',
}

export enum MeteoType {
  meteoFrance = 'meteo-france',
  whiteMountains = 'white-mountains',
}

export enum HistoricalSnowfallType {
  monthly = 'monthly',
  annual = 'annual',
}

export enum ReviewsOrderBy {
  date = 'date',
  highestRating = 'highestrating',
  lowestRating = 'lowestrating',
}

export interface ISnowStatus {
  last24: number;
  base: number;
  middle?: number;
  summit: number;
  last72: number;
}

export interface ILiftsStat {
  open?: number;
  total: number;
}

export interface IRunsStat {
  open?: number;
  openPercent?: number;
  total: number;
}

export interface IAcresStat {
  open?: number;
  total: number;
}

export interface IKilometersStat {
  open?: number;
  total: number;
}

export interface IRange {
  open?: number;
  total: number;
}

export interface ITerrainRunsData {
  openNum: number;
  openLen?: number;
  totalLen: number;
  total: number;
  openPercent: number;
  totalPercent: number;
}

export interface INordicStats {
  total: number;
  skategroomed: number;
  trackset: number;
  open: number
}

/**
 * Resort terrain info on Snow Report Page
 */
export interface IResortSnowReportTerrain {
  lifts?: ILiftsStat;
  runs?: IRunsStat;
  runsBeginner?: ITerrainRunsData;
  runsIntermediate?: ITerrainRunsData;
  runsAdvanced?: ITerrainRunsData;
  runsExpert?: ITerrainRunsData;
  acres?: IAcresStat;
  km?: IKilometersStat;
  parks?: IRange;
  pipes?: IRange;
  tubes?: IRange;
  nordic?: INordicStats;
  canReturnBySki?: ReturnBySki;
}

export interface IResortTerrainDetail {
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  title: string;
  value: string;
  className?: string
}

export interface IOpenStat {
  openFlag?: OpenFlag;
  openingDate?: string;
  closingDate?: string;
}

export type WindDirection = string;

export type IWeatherInfo = {
  base: number;
  summit: number;
  weatherLogo: MeteoType.meteoFrance | MeteoType.whiteMountains;
  weatherItems: Array<IWeatherItem>;
};

export interface IWeatherItem {
  datetime: string;
  base?: IWeatherItemDetail;
  summit?: IWeatherItemDetail;
}

export interface IWeatherItemDetail {
  temp: {
    min: number,
    max: number,
  },
  wind: {
    /**
   * This is in mps (meters per second) cause mobile app expects it like this and we cant quickly change it there
   * TODO: add windKmh field and use it instead of wind
   */
    speed: number,
    direction: number,
    gusts: number,
  },
  snow: {
    snowfall: number,
    probability: number,
    density?: number,
    snowline?: number,
  },
  type: WeatherType;
}

export type ISnowfallItem = {
  date: string;
  totalSnow: number;
  snowDays: number;
  baseDepth: number;
  summitDepth: number;
  maxBaseDepth: number;
  biggestSnowfall: number;
};

export interface IResortBasicStatusPartial {
  surfaceType: SurfaceType;
  surfaceTypeBottom: SurfaceType;
  snow: ISnowStatus;
  lifts?: ILiftsStat;
  runs?: IRunsStat;
  km?: IKilometersStat;
  updatedAt: string;
  status: IOpenStat;
  currentWeather?: IWeatherItem;
  runsKm?: IRunsStat;
}

/**
 * Region details split as object
 */
export interface IRegionDetails {
  uuid: string;
  name: string;
  country: string;
  state: string;
}

/**
 * Main SEO and static info for resort page
 */
export interface IResortPageInfo {
  uuid: string;
  title: string;
  image: string;
  smallImage?: string;
  logo?: string;
  priceRange?: string;
  isPartner: boolean;
  video?: string;
  website?: string;
  liftsUrl?: string;
  rentalUrl?: string;
  lessonsUrl?: string;
  twitter?: string;
  phone?: string;
  region: IRegionShort;
}

export interface IResortShortWeatherInfo {
  snow: {
    'forecast72': number,
    'last72': number },
  'temp': {
    'min': number,
    'max': number
  },
  'symbol': string
}

export interface IResortSnowDepths {
  base?: number,
  middle?: number,
  summit?: number,
}

export type IResortCreditLine = string | null;

export interface ISnowHistoryItem {
  date: string;
  snow: number;
}

export interface IOpeningTimeItem {
  day: number;
  from: string;
  to: string;
  isNight?: boolean;
}

/**
 * Main SEO and static info for resort page
 */
export type IResortPageSnowInfo = IResortPageInfo & IResortBasicStatusPartial & {
  terrain?: IResortSnowReportTerrain;
  depths?: IResortSnowDepths; // NOTE: V3 api
  depthAverages?: IResortSnowDepths;
  recent?: Array<ISnowHistoryItem>;
  forecast?: Array<ISnowHistoryItem>;
  schedule?: Array<IOpeningTimeItem>;
  comment?: string;
  creditLine?: IResortCreditLine
};

export type IResortPathInfoResponse = {
  data: IResortPathInfo;
} | {
  error: string;
  errorCode: number;
};

export interface ITerrrainRunsOnly {
  beginner?: ITerrainRunsData;
  intermediate?: ITerrainRunsData;
  advanced?: ITerrainRunsData;
  expert?: ITerrainRunsData;
}

export interface ITerrainRuns extends ITerrrainRunsOnly {
  total?: number;
  totalRunsLength: number;
  longestRunLength: number;
  skiableAc: number;
  skiableKm: number;
  skiableNightAc: number;
  skiableNightKm: number;
  snowMakingAc: number;
  snowMakingKm: number;
}

export interface ITerrainLifts {
  gondolasAndTrams: number;
  eightPerson: number;
  sixHS: number;
  fourHS: number;
  fourChairs: number;
  threeChairs: number;
  doubleChairs: number;
  surface: number;
}

export interface IElevation {
  summit: number;
  verticalDrop: number;
  base: number;
}

export interface ITerrainShort {
  acres: IRange;
  km: IRange;
  skiReturn: ReturnBySki;
  parks?: IRange;
}

/**
 * Resort Terrain info for Resort Guide and TrailMap page
 */
export interface IResortTerrainInfo {
  runs: ITerrainRuns;
  lifts: ITerrainLifts;
  elevation: IElevation;
  terrain: ITerrainShort;
  dates: IResortDates;
}

export interface ITrailMap {
  image: string;
  thumbnail: string;
  shortInfo: string;
  longInfo: string;
}

export interface IResortDates {
  projectedOpening: string;
  projectedClosing: string;
  projectedDaysOpen: number;
  yearsOpen: number;
  averageSnowfall: number;
  daysOpenLastYear: number;
}

export interface IResortTrailmapPage extends IResortTerrainInfo {
  openFlag: OpenFlag;
  trailMap: ITrailMap;
}

/**
 * Resort Overview
 */

export type IResortStrengthsOverview = {
  bgImage: string;
  ratings: Array<IResortStrengths>;
};

export type IResortStrengths = {
  position: number;
  category: ResortTopRated;
  region: IRegionShort;
};

export type IDescriptionsResort = {
  tab: string;
  description: string;
};

export type IContactInformation = {
  phoneNumber: string;
  email: string;
  address: IContactAddress;
};

export type IContactAddress = {
  postOffice: string;
  city: string;
  country: string;
  lat: string;
  lng: string;
};

/**
 * Choise badge coolor
 */

export enum BadgeColor {
  Normal = '',
  White = '-white',
}

export interface IDailyResortLiftTickets {
  resort: IResortPathInfo
  adult2DayPrice: string | null
  adult6DayPrice: string | null
  adultAge: string | null
  adultHalfDayPrice: string | null
  adultWeekdayPrice: string | null
  adultWeekendPrice: string | null
  children2DayPrice: string | null
  children6DayPrice: string | null
  childrenAge: string | null
  childrenHalfDayPrice: string | null
  childrenWeekdayPrice: string | null
  childrenWeekendPrice: string | null
  currency: string | null
  purchaseUrl: string | null
  senior2DayPrice: string | null
  senior6DayPrice: string | null
  seniorAge: string | null
  seniorHalfDayPrice: string | null
  seniorWeekdayPrice: string | null
  seniorWeekendPrice: string | null
  teenager2DayPrice: string | null
  teenager6DayPrice: string | null
  teenagerAge: string | null
  teenagerHalfDayPrice: string | null
  teenagerWeekdayPrice: string | null
  teenagerWeekendPrice: string | null
  note: string | null
  lastModified: string | null
}

export interface ISeasonResortLiftTickets {
  resort: IResortPathInfo
  adultAge: string | null
  adultPrice: string | null
  childrenAge: string | null
  childrenPrice: string | null
  currency: string | null
  expires: string | null
  purchaseUrl: string | null
  season: string | null
  seniorAge: string | null
  seniorPrice: string | null
  teenagerAge: string | null
  teenagerPrice: string | null
  note: string | null
  lastModified: string | null
}

export interface ISkiResortShop {
  uuid: string,
  id: string,
  title: string,
  town: string,
  province: string,
  street: string,
  country: string,
  distance?: number,
  resortId?: number,
  url: string,
  linkToShop: string,
  image: string,
  thumbnail: string,
  discount?: number,
}
