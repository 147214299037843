/* eslint-disable global-require */
import { getLocale } from 'i18n/locale';
import { IS_NODE_ENV_DEVELOP } from 'ots-constants';
import { IUrlTranslations } from 'portalConfig';

// eslint-disable-next-line import/no-dynamic-require
const translationMap: IUrlTranslations = require(`../../public/urls/${getLocale()}.json`);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const defaultUrlTranslations: IUrlTranslations = {
  global: {
    'ski-resorts': 'ski-resorts',
    skireport: 'skireport',
    'nearby-resorts': 'nearby-resorts',
  },
  region: {
    'ski-resorts': 'ski-resorts',
    'open-resorts': 'open-resorts',
    'projected-openings': 'projected-openings',
    'projected-closing': 'projected-closing',
    cams: 'cams',
    skireport: 'skireport',
    'lift-tickets': 'lift-tickets',
    lodging: 'lodging',
  },
  resort: {
    'lift-tickets': 'lift-tickets',
    'historical-snowfall': 'historical-snowfall',
    'ski-resort': 'ski-resort',
    cams: 'cams',
    firsthand: 'firsthand',
    reviews: 'reviews',
    trailmap: 'trailmap',
    skireport: 'skireport',
    skirentals: 'skirentals',
    lodging: 'lodging',
    weather: 'weather',
    flights: 'flights',
    cars: 'cars',
    'book-trip': 'book-trip',
  },
};

export const getPortalUrlTranslations = () => (IS_NODE_ENV_DEVELOP ? defaultUrlTranslations : translationMap);
