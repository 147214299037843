/* eslint-disable no-console */

import type { BrowserOptions } from '@sentry/browser';
import type { NodeOptions } from '@sentry/node';
import { getLocale } from 'i18n/locale';
import {
  BUILD_DATE, ENV, IS_NODE_ENV_DEVELOP, IS_SSR, isProductionServer,
} from 'ots-constants';

import * as SentrySource from '@sentry/node';
import { RewriteFrames, Dedupe } from '@sentry/integrations';
import { Integrations } from '@sentry/tracing';
import type { Scope, StackFrame } from '@sentry/types';
import { getPrebid } from 'prebidConfig';
import { DATE_TIME_FORMAT, formatDate } from 'util/formatDate';

// TODO: const LOG_LOCALLY = IS_DEVELOP;
const LOG_LOCALLY = true;
const LOG_REMOTELY = !IS_NODE_ENV_DEVELOP;
// const LOG_REMOTELY = true;

const forceDebugSentry = !IS_SSR && global?.location?.hash === '#forceDebugSentry';

if (forceDebugSentry) {
  console.warn('Sentry debug mode is forced');
}

const mode = IS_NODE_ENV_DEVELOP ? 'hot-reload' : 'bundled';

const extras = {
  build: BUILD_DATE,
  date: formatDate(new Date(+BUILD_DATE!), DATE_TIME_FORMAT),
};

export const initDiag = () => {
  if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
    const integrations = [];
    if (
      process.env.NEXT_IS_SERVER === 'true'
      && process.env.NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR
    ) {
      // For Node.js, rewrite Error.stack to use relative paths, so that source
      // maps starting with ~/_next map to files in Error.stack with path
      // app:///_next
      integrations.push(
        new RewriteFrames({
          iteratee: (frame: StackFrame) => {
            // eslint-disable-next-line no-param-reassign
            frame.filename = frame.filename!.replace(
              process.env.NEXT_PUBLIC_SENTRY_SERVER_ROOT_DIR!,
              'app:///',
            );
            // eslint-disable-next-line no-param-reassign
            frame.filename = frame.filename.replace('.next', '_next');
            return frame as StackFrame;
          },
        }),
      );
    }

    if (
      process.env.NEXT_IS_SERVER !== 'true'
    ) {
      // for browser add tracemaps
      integrations.push(
        new Dedupe(),
        new Integrations.BrowserTracing(),
      );
    }
    const options: NodeOptions = {
      dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
      release: process.env.BUILD_ID,
      debug: !!IS_NODE_ENV_DEVELOP || forceDebugSentry,
      sampleRate: 1,
      tracesSampleRate: (isProductionServer && !forceDebugSentry) ? 0.0025 : 1.0,
      integrations,
      beforeSend(event, hint) {
        let shouldIgnore: boolean = false;
        if (event.exception?.values) {
          const { values } = event.exception;
          shouldIgnore = !!values.find((v) => {
            const { stacktrace } = v;
            const ignore: boolean = !!(stacktrace?.frames ?? []).find((frame) => (
              (frame.filename ?? '').indexOf('https://live.primis.tech') >= 0 // `TypeError: Cannot read properties of null (reading 'innerHeight')`
              || (frame.filename ?? '').indexOf('/content/video/hls/hls') >= 0 // `TypeError: Cannot read properties of null (reading 'clearTimeout')`
              || (frame.filename ?? '').indexOf('/perfect-scrollbar/dist/perfect-scrollbar.esm') >= 0 // `TypeError: Cannot read property 'scrollTop' of null`
            ));

            const shouldSkip = ignore
              || (v.value ?? '').indexOf('Request [https://api.onthesnow.com/api/v2/translations] aborted') >= 0
              || (v.value ?? '').indexOf('null is not an object (evaluating \'window.top.pageYOffset\')') >= 0 // ios specific iframe error
              || (v.value ?? '').indexOf('Cannot read properties of null (reading \'pageYOffset\')') >= 0 // android specific iframe error
              || (v.value ?? '').indexOf('Cannot read property \'pageYOffset\' of null') >= 0 // android specific iframe error
              || (v.value ?? '').indexOf('googletag.pubads is not a function') >= 0 // AdBlock
              || (v.value ?? '').indexOf('window.top is null') >= 0 // iframe error
              || (v.value ?? '').indexOf('undefined is not an object (evaluating \'window.top.pageYOffset\')') >= 0 // iframe error
              || (v.value ?? '').indexOf('Unexpected token \'...\'. Expected a property name.') >= 0 // ?
              || (v.value ?? '').indexOf('Unexpected token ...') >= 0 // ?
              || (v.value ?? '').indexOf('] aborted') >= 0 // Aborted requests
              || (v.value ?? '').indexOf('Network Error') >= 0; // offline errors logging is of no use

            return shouldSkip;
          });
        }
        if (!isProductionServer || forceDebugSentry) {
          console.log('[sentry]', shouldIgnore ? 'ignored' : 'sent', event.exception?.values, hint);
        }
        if (shouldIgnore) {
          return null;
        }
        return event;
      },
    };
    if (!IS_SSR) {
      (options as BrowserOptions).allowUrls = [process.env.NEXT_PUBLIC_WEBSITE_DOMAIN!, getPrebid()];
    } else {
      options.tracesSampleRate = 1;
    }
    SentrySource.init(options);
    SentrySource.setTag('mode', mode);
    SentrySource.setTag('envCode', ENV);
    SentrySource.setTag('server', IS_SSR);
    SentrySource.setTag('locale', getLocale());
    SentrySource.setExtras(extras);
  }
};

export type ErrorLike = {
  /**
   * Error will be tagged with this
   */
  tag?: string;
  /**
   * Logger will dump message in console before error as breadcrumb
   */
  message?: string;
  /**
   * Error to log
   */
  error: Error;
  /**
   * Arbitrary data to serialize and log
   */
  data?: any;
};

export type MessageLike = {
  /**
   * Error will be tagged with this
   */
  tag?: string;
  /**
   * Logger will dump message in console before error as breadcrumb
   */
  message?: string;
  /**
   * Arbitrary data to serialize and log
   */
  data?: any;
};

export const logError = async ({
  tag, message, error, data,
}: ErrorLike) => {
  if (LOG_LOCALLY) {
    if (tag || message || data) {
      const arr = [];
      if (tag) {
        arr.push(`[${tag}]`);
      }
      if (message) {
        arr.push(message);
      }
      if (typeof data !== 'undefined') {
        arr.push(data);
      }
      console.log(...arr);
    }
    console.error(error);
  }
  if (LOG_REMOTELY) {
    SentrySource.withScope((scope: Scope) => {
      if (tag) {
        scope.setTag('tag', tag);
      }
      if (message || data) {
        SentrySource.addBreadcrumb({
          message, data,
        });
      }
      SentrySource.captureException(error);
    });
  }
};

export const logMessage = async ({ tag, message, data }: MessageLike) => {
  if (LOG_LOCALLY) {
    if (tag || message || data) {
      const arr = [];
      if (tag) {
        arr.push(`[${tag}]`);
      }
      if (message) {
        arr.push(message);
      }
      if (typeof data !== 'undefined') {
        arr.push(data);
      }
      console.log(...arr);
    }
  }
  if (LOG_REMOTELY) {
    SentrySource.withScope((scope: Scope) => {
      if (tag) {
        scope.setTag('tag', tag);
      }
      if (message || data) {
        SentrySource.addBreadcrumb({
          message, data,
        });
      }
    });
  }
};

export const Diagnostics = {
  /**
   * Use for most important messages
   */
  error: (props: Partial<ErrorLike>) => {
    if (!props.error && props.message) {
      // eslint-disable-next-line no-param-reassign
      props.error = new Error(props.message);
    }
    logError(props as ErrorLike);
  },

  /**
   * Use for messages useful for crash dumps
   * @param props
   */
  message: (props: MessageLike) => {
    logMessage(props);
  },

  /**
   * Use for least critical data, assume this can never reach crash dumps
   * @param args
   */
  debug: (...args: any[]) => console.log(...args), // TODO: More "just log" methods

  setUser: async (userId: string) => {
    SentrySource.setUser({ id: userId });
  },
};

if (!IS_SSR) {
  Diagnostics.message({
    tag: 'init',
    message: 'Initialized Sentry',
    data: {
      ...extras,
      mode,
      locale: getLocale(),
      release: process.env.BUILD_ID,
      env: ENV,
    },
  });
} else {
  console.log('[sentry]', getLocale(), mode, extras.date, process.env.BUILD_ID, ENV);
}
