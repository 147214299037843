import classNames from 'classnames';
import { getLocale } from 'i18n/locale';
import { ELocale } from 'i18n/localeEnum';
import { localeConfig } from 'localeConfig';
import { useCallback, useEffect, useState } from 'react';
import styles from './styles.module.scss';

export interface ILangOption {
  value: ELocale,
  label: string,
  title: string,
  flag: string
}

export const options: Array<ILangOption> = [
  {
    value: ELocale.EN_US,
    label: 'US',
    title: 'US',
    flag: '/assets/flags/US.png',
  },
  {
    value: ELocale.EN_GB,
    label: 'UK',
    title: 'UK',
    flag: '/assets/flags/UK.png',
  },
  {
    value: ELocale.SV_SE,
    label: 'SV',
    title: 'SV',
    flag: '/assets/flags/SV.png',
  },
  {
    value: ELocale.SK_SK,
    label: 'SK',
    title: 'SK',
    flag: '/assets/flags/SK.png',
  },
  {
    value: ELocale.PL_PL,
    label: 'PL',
    title: 'PL',
    flag: '/assets/flags/PL.png',
  },
  {
    value: ELocale.DE_DE,
    label: 'DE',
    title: 'DE',
    flag: '/assets/flags/DE.png',
  },
  {
    value: ELocale.ES_ES,
    label: 'ES',
    title: 'ES',
    flag: '/assets/flags/ES.png',
  },
  {
    value: ELocale.IT_IT,
    label: 'IT',
    title: 'IT',
    flag: '/assets/flags/IT.png',
  },
  {
    value: ELocale.NN_NO,
    label: 'NO',
    title: 'NO',
    flag: '/assets/flags/NO.png',
  },
  {
    value: ELocale.DA_DK,
    label: 'DA',
    title: 'DA',
    flag: '/assets/flags/DA.png',
  },
  {
    value: ELocale.FR_FR,
    label: 'FR',
    title: 'FR',
    flag: '/assets/flags/FR.png',
  },
  {
    value: ELocale.NL_NL,
    label: 'NL',
    title: 'NL',
    flag: '/assets/flags/NL.png',
  },
  {
    value: ELocale.CS_CZ,
    label: 'CZ',
    title: 'CZ',
    flag: '/assets/flags/CS.png',
  },
];

export const SimpleLangSelect = () => {
  const [visible, setVisible] = useState<boolean>(false);

  const links = options.map((o) => (
    <a key={o.flag} href={localeConfig[o.value as ELocale].websiteDomain}>
      <div>
        {visible && (
          <img
            src={o.flag}
            alt={o.label}
            width={18}
            height={18}
            title={o.title}
          />
        )}
        <span>
          {o.title}
        </span>
      </div>
    </a>
  ));

  const value = options.find((o) => o.value === getLocale())!;

  const hideLangSelect = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  const showLangSelect = useCallback(() => {
    setVisible(true);
  }, [setVisible, hideLangSelect]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (visible) {
      document.addEventListener('click', hideLangSelect);
      return () => {
        document.removeEventListener('click', hideLangSelect);
      };
    }
  }, [visible, hideLangSelect]);

  return (
    <div className={styles.langWrapper}>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/no-static-element-interactions */}
      <a
        onClick={showLangSelect}
        className={styles.langButton}
      >
        <div>
          <img
            src={value.flag}
            alt={value.label}
            width={18}
            height={18}
            title={value.title}
          />
          <span>
            {value.title}
          </span>
        </div>
      </a>
      <div className={classNames(styles.langDropdown, visible && styles.visible)}>
        {links}
      </div>
    </div>
  );
};
