import { Diagnostics } from 'util/diagnostics';
import { getLocale } from 'i18n/locale';
import { ELocale } from 'i18n/localeEnum';

export const PREBID_VAR = 'isPrebidScriptsReady';
export const PREBID_FN_VAR = 'onPrebidReady';
const DEBUG_TAG = 'ad prebid';

export const isPrebidDisabled = () => getLocale() === ELocale.EN_US || (global as any)?.location?.search === '?noprebid';

const isPrebidScriptsReady = () => !!(global as any)[PREBID_VAR] || isPrebidDisabled();

const setPrebidScriptsReady = (v: boolean) => {
  (global as any)[PREBID_VAR] = v;
};

const setPrebidReadyCallback = (fn: () => void) => {
  (global as any)[PREBID_FN_VAR] = fn;
};

const hasPrebidReadyCallback = () => !!(global as any)[PREBID_FN_VAR] && typeof (global as any)[PREBID_FN_VAR] === 'function';

function onPrebidReadyFallback() {
  Diagnostics.message({ message: 'onPrebidReadyFallback', tag: DEBUG_TAG });
  setPrebidScriptsReady(true);
}

export function onPrebidReadyMaker(onReadyCallback?: () => void) {
  Diagnostics.message({ message: 'onPrebidReadyMaker', tag: DEBUG_TAG, data: { hasCallback: !!onReadyCallback } });
  if (onReadyCallback) {
    if (isPrebidScriptsReady()) {
      onReadyCallback();
    }
    setPrebidReadyCallback(() => {
      onPrebidReadyFallback();
      onReadyCallback();
    });
  } else if (
    !hasPrebidReadyCallback()
  ) {
    setPrebidReadyCallback(onPrebidReadyFallback);
  }
}

export function isPrebidInitialized() {
  return isPrebidScriptsReady();
}

// initialize onPrebidReady when app loaded to ASAP give access to this function for prebid scripts
// if they will be loaded faster than adUnitContext would be initialized
onPrebidReadyMaker();
